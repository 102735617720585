.closeContainer {
    flex: 0.15;
    display: flex;
    margin-top: -25px;
    margin-right: 15px;
    height: 30px;
    justify-content: flex-end;
}


.modalContainer {
    display: flex;
    margin-left: 40px;
    margin-top: 40px;
    margin-bottom: 40px;
    max-height: 80vh;
}

.formContainer {
    flex: 0.85;
    display: flex;
    flex-direction: column;
}

.modalTitle {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    letter-spacing: -2px;
    color: #000000;
    margin-bottom: 20px;
}

.modalHelperText {
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: red;
}
