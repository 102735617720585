input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.ReactModal__Overlay {
  opacity: 0;
  transform: translateY(-100px);
  transition: all 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateY(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateY(-100px);
}
